#preloader {
    position: fixed;
    width: 100%;
    min-height: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 9;
    opacity: 0.7;
  }
  
  .sk-three-bounce {
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: white;
  }
  
  .sk-three-bounce .sk-child {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #36c95f;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  }
  
  .sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes sk-three-bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  