@import "../../../components/misc/config";

.progressbar {
  position: relative;
  background-color: none;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    .progress {
      box-shadow: 0 0 10px rgba(darken($light-color, 19), 0.7);
    }

    .tip {
      top: -150%;
      opacity: 1;
    }
  }
  .title {
    font-size: 10pt;
    font-weight: 700;
    text-transform: uppercase;
    // letter-spacing: ;
  }

  .bar {
    width: 100%;
    background-color: darken($light-color, 10);
    padding: 0 20px;
    border-radius: 50px;
    font-size: 10pt;
    box-shadow: 0 0 10px $light-color;
    opacity: 0.5;
    position: relative;
    .title {
      z-index: 10;
    }
    .percentage {
      // color: $light-color;
      font-weight: 700;
      z-index: 10;
    }
    .progress {
      content: "";
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      background-color: rgba($dark-color, 0.7);
      z-index: -1;
    }
  }

  &.success {
    .progress {
      background-image: linear-gradient(
        to left,
        $primary-color,
        darken($primary-color, 30)
      );
    }
    .percentage {
      color: $light-color;
    }
  }
  &.danger {
    .progress {
      background-image: linear-gradient(
        to left,
        $danger-color,
        darken($danger-color, 30)
      );
    }
  }
  &.warning {
    .progress {
      background-image: linear-gradient(
        to left,
        $warning-color,
        darken($warning-color, 30)
      );
    }
  }
  &.info {
    .progress {
      background-image: linear-gradient(
        to left,
        $info-color,
        darken($info-color, 30)
      );
    }
  }
  &.purple {
    .progress {
      background-image: linear-gradient(
        to left,
        $purple-color,
        darken($purple-color, 30)
      );
    }
  }
  &.pink {
    .progress {
      background-image: linear-gradient(
        to left,
        $pink-color,
        darken($pink-color, 30)
      );
    }
  }

  .tip {
    width: 100px;
    height: 20px;
    font-size: 8pt;
    background-color: $light-color;
    box-shadow: 0 0 5px darken($light-color, 20);
    position: absolute;
    top: 0%;
    right: -10%;
    line-height: 20px;
    padding: 0 10px;
    font-weight: 700;
    opacity: 0;
    transition: all 200ms linear;
    &::after {
      content: "";
      height: 10px;
      width: 10px;
      background-color: $light-color;
      box-shadow: 5px 0 5px darken($light-color, 20);
      position: absolute;
      bottom: -20%;
      left: 20%;
      z-index: inherit;
      transform: rotate(45deg);
    }
  }
}

.bb_primary {
  border-bottom: 2px solid $primary-color;
}

.small_card {
  // width: 20%;
  width: 150px;
  height: 100px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px darken($light-color, 10);
  margin-bottom: 20px;
  border: 1px solid rgba(darken($light-color, 20), 0.7);
  cursor: pointer;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    font-size: 10pt;
    font-weight: 700;
  }
  small {
    font-size: 8pt;
  }
  &:hover {
    box-shadow: 0 0 10px darken($light-color, 20);
    background-color: lighten($primary-color, 40);
    * {
      // color: $light-color;
    }
  }
}

.card_wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.small_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  &.card {
    box-shadow: 0 0 10px darken($light-color, 10);
    border: 1px solid rgba(darken($light-color, 20), 0.7);
  }
  .table_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;

    &.card {
      box-shadow: 0 0 10px darken($light-color, 10);
      border: 1px solid rgba(darken($light-color, 20), 0.7);
    }
    &.line_bottom{
        border-bottom: 3px solid rgba(darken($light-color, 20), 0.7);
    }

    &:hover{
        background-color: rgba(darken($light-color, 20), 0.7);
        transition: background-color 300ms ease-in-out;
    }

    a {
      color: inherit;
    }

    .column {
      width: 50px;
      font-weight: 400;
      font-size: 10pt;
      color: inherit;
      padding: 0px 10px;
      &.grow {
        width: auto;
        flex-grow: 1;
      }

      &.circle{
          border-radius: 50%;
          padding: 10px;
          text-align: center;
      }

      &.warning {
        background-image: linear-gradient(
            to left,
            $warning-color,
            lighten($warning-color, 40)
          );
        color: $dark-color;
      }

      &.success {
        background-image: linear-gradient(
            to left,
            $primary-color,
            lighten($primary-color, 40)
          );
        color: $dark-color;
      }

      &.pink{
        background-image: linear-gradient(
            to left,
            $pink-color,
            lighten($pink-color, 20)
          );
          color: $dark-color;
      }
    }
  }
}
