

.flex_cc{
    display: flex;
    justify-content: space-between;
    margin-top: .5em;
    strong{
        font-weight: 600;
    }
}
