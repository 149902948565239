@import '../misc/config';

.chat{
    max-height: 50vh;
    overflow-y: scroll;
    background-color: rgb(232, 238, 238);
    padding: 1em;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar{
        display: none;
    }
    .chat_list{
        margin: 10px auto;
        .chat_item{
            display: flex;
            margin-bottom: 1em;
            background-color: $white;
            // border-radius: 30px;
            padding: 1em;
            box-shadow: 0 0 10px rgba(200,200,200,.4);
            &.right{
                .comment{
                    order: 2;
                }
                .user_abbr{
                    order: 1;
                }
            }
            .comment{
                display: flex;
                flex-direction: column;
                width: 90%;
                .username{
                    color: lighten($dark-color, 30);
                    letter-spacing: 2px;
                    font-size: 12pt;
                }
                .timestamp{
                    font-size: 10pt;
                    color: lighten($dark-color, 40);
                    margin-top: .5em;
                }
            }
            .user_abbr{
                height: 30px;
                width: 30px;
                background-color: $primary-color;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                margin-right: 1em;
                box-shadow: 0 0 10px rgba($dark-color, .05);
                color: $white;
                font-weight: 900;
                letter-spacing: -2px;
            }
            p{
                font-size: 10pt;
                color: #333;
            }
        }
    }
}