@import '../misc/config';

.custom_form_card{
    margin: 0.2rem;
    padding: 2rem;
    text-align: left;
    color: inherit;
    border: 0px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba($color: darken($light-color, 15), $alpha: .5);
    width: 100%;
    min-height: 80vh;
    position: relative;
    background-color: white;
    transition: color 0.15s ease, border-color 0.15s ease;
    &::before, &::after{
        display: block;
        content: '';
        position: absolute;
        width: 100px;
        height: 100px;
        z-index: -1;
    }
}
