/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
  scroll-behavior: smooth;
}
.btn {
  font-size: 8pt !important;
}

.text-10 {
  font-size: 10pt;
}

.btn{
  border-radius: 0 !important;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  font-weight: 400;
  word-wrap: break-word;
  line-break: strict;
  background-color: #f8f8f8;
}
header {
  height: 100vh;
  min-height: 300px;
  background-size: cover;
  background-image: url("../images/banner.jpg");
  background-attachment: fixed;
  color: aliceblue;
}

.logo {
  width: 130px;
  height: 35px;
  object-fit: cover;
}

.navbar-brand p {
  font-size: 8pt;
  padding-left: 10px;
  margin: 0;
}

.toast {
  opacity: 1;
  width: 100%;
  max-width: 100%;
}

.bolder {
  font-weight: 400;
}

input,
textarea,
select,
button,
.btn {
  font-size: 11pt;
  font-family: inherit;
  outline: none;
}
.form-label {
  font-size: 11pt;
}
.form-control,
.custom-select {
  font-size: 11pt;
  box-shadow: 0 0 0 0 white;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus,
.custom-select:focus {
  box-shadow: 0 0 0 0 white;
  border-color: #ccd4d8;
}

#sidebarCollapse {
  font-size: 20px;
}

img {
  object-fit: cover;
}

a,
a:hover {
  text-decoration: none;
}

.icon-background {
  background-color: rgb(236, 236, 236);
}
.push-up {
  position: relative;
  top: -100px;
}

h3 {
  font-weight: 600;
}
footer {
  margin-top: 60px;
  padding: 50px;
  border-top: 1px solid rgba(223, 223, 223, 0.43);
  text-align: center;
}

.footer {
  margin-top: 15px;
  padding: 25px;
  border-top: 1px solid rgba(223, 223, 223, 0.43);
  text-align: center;
}
.under-line {
  border-bottom: 1px solid rgba(231, 233, 230, 0.199);
  padding: 0;
  margin-top: 0;
}

.hidden-spacer {
  height: 56px;
}

.header-title {
  font-size: 4em;
  font-weight: 900;
  line-height: 1;
}

.call-to-action {
  position: relative;
  top: 100px;
  text-align: center;
}

.call-to-action p {
  font-size: 14pt;
  font-weight: 300;
}

.about-header {
  position: relative;
  top: 100px;
  text-align: left;
}

.about-header p {
  font-size: 14pt;
  font-weight: 300;
}


/* ----------------------
Dahsboard navbar Styles
-------------------------*/
.full-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 200px;
  color: white;
  background-color: green;
  z-index: 998;
}

#dashboard-header {
  width: 100%;
  height: 6vh;
  background-color: green;
  color: aliceblue;
}

#dashboard-header .navbar-collapse {
  background-color: green;
  width: 100%;
}

#dashboard-header .nav-item .nav-link {
  font-size: 10pt;
  color: aliceblue;
  padding: 3px 20px;
}

#dashboard-header span {
  cursor: pointer;
}

/* Notification label styles */

.notification-label {
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #df2222;
  top: 3px;
  right: 30px;
  box-shadow: 0 0 0 rgba(247, 9, 9, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 9, 9, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(247, 9, 9, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 9, 9, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(247, 9, 9, 0.4);
    box-shadow: 0 0 0 0 rgba(247, 9, 9, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(247, 9, 9, 0);
    box-shadow: 0 0 0 10px rgba(247, 9, 9, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(247, 9, 9, 0);
    box-shadow: 0 0 0 0 rgba(247, 9, 9, 0);
  }
}

.header-inner {
  height: 65px;
  padding-bottom: 5px;
  background-size: cover;
  background-image: url("../images/banner.jpg");
  color: aliceblue;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 30%);
}

.background-gray {
  background-color: rgb(101, 134, 46);
  color: aliceblue;
  position: relative;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px;
  height: 100vh;
  z-index: 999;
  background: rgb(60, 133, 102);
  color: #fff;
  transition: all 0.3s;
  overflow-y: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
  left: 0;
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: rgba(60, 133, 102, 0.4);
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#dismiss:hover {
  background: #fff;
  color: #8fbc8f;
}

.overlay {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: rgba(60, 133, 102, 0.4);
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #8fbc8f;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  text-decoration: none;
  color: #eee;
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #fff;
  background: #8fbc8f;
}

#sidebar ul li.active > a,
#sidebar a[aria-expanded="true"] {
  color: #fff;
  background: #8fbc8f;
}

#sidebar a[data-toggle="collapse"] {
  position: relative;
}

#sidebar .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#sidebar ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: rgba(60, 133, 102);
}

#sidebar ul.CTAs {
  padding: 20px;
}

#sidebar ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

#sidebar a.download {
  background: #fff;
  color: #a9a9a9;
}

#sidebar a.article,
#sidebar a.article:hover {
  background: #8fbc8f !important;
  color: #fff !important;
}

.sidebar-dropdown {
  min-width: 90%;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: aliceblue;
  text-align: left;
  list-style: none;
  background-color: #8fbc8f;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

#sidebar ul li .sidebar-dropdown a {
  color: white;
}

/* Gallery styles */
.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: stretch;
}
.gallery-image {
  margin: 10px 5px;
  width: 90%;
}

.gallery-top-image{
  display: block;
  width: 285px;
  height: auto;
  object-fit: cover;
  align-self: center;
}
.gallery-image img {
  object-fit: contain;
  width: 100%;
  height: 80px;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.clickable {
  cursor: pointer;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  margin-top: 150px;
  width: 100%;
  padding: 20px;
  min-height: 100vh;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 990px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
  .gallery-image {
    margin: 10px 5px;
    width: 90%;
  }

  .gallery-image img {
    object-fit: contain;
    width: 100%;
    height: 80px;
    border: 1px solid white;
    border-radius: 5px;
  }

  .content{
    margin-top: 180px;
  }
}

@media (max-width: 786px) {
  .call-to-action {
    top: 50px;
    padding: 10px;
  }
  header {
    height: 100%;
    min-height: 100vh;
    padding-bottom: 10em;
  }

  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 250px);
  }
  #sidebarCollapse span {
    display: none;
  }
  #dashboard-header {
    height: 100%;
    min-height: 7vh;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-image {
    margin: 10px 5px;
    width: 90%;
  }

  .gallery-image img {
    object-fit: contain;
    width: 100%;
    height: 80px;
    border: 1px solid white;
    border-radius: 5px;
  }
}