.button_loader{
    display: flex;
    width: 100%;
    // height: ;
    justify-content: space-around;
    min-width: 50px;
    span{
        content: 'dd';
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: white;
        top: 0;
        position: relative;
        transition: top 300ms linear;
        &:nth-of-type(1){
            animation: bounce 1s linear infinite;
        }
        &:nth-of-type(2){
            animation: bounce 1s linear infinite;
            animation-delay: 100ms;
        }
        &:nth-of-type(3){
            animation: bounce 1s linear infinite;
            animation-delay: 200ms;
        }
    }
    svg{
        transform: rotate(0deg);
        animation: rotate 1s linear infinite;
    }
}

@keyframes bounce{
    0%{
        top: 0;
    }
    25%{
        top: -4px;
    }
    50%{
        top: 0px;
    }
    75%{
        top: 4px;
    }
    100%{
        top: 0;
    }
}

@keyframes rotate {
    to{
        transform: rotate(360deg);
    }
}