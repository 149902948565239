@import '../../misc/config';


.select_wrapper{
    width: 100%;
    border: 1px solid darken($white, 10);
    cursor: pointer;
    position: relative;
    background-color: $white;
    /* Remove these */
        margin-top: 10px;
    /*  */
    input{
        width: 100%;
        outline-width: 0;
        border: 1px solid darken($white, 25);
        font-size: 8pt;
        padding: 10px;
    }

    .selected_item{
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 10pt;
        display: block;
        z-index: 1;
        overflow: hidden;
        
    }
    .data_wrapper{
        position: absolute;
        width: 100%;
        max-height: 20vh;
        overflow-y: scroll;
        box-shadow: 0 0 10px rgba(150,150,150, .4);
        background-color: $white;
        z-index: 3;
        ul{
            li{
                list-style-type: none;
                font-size: 10pt;
                padding: 10px;
                border-bottom: 1px solid darken($white, 5);
                &:hover{
                    background-color: darken($white, 10);
                }
            }
        }
    }
}