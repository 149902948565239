* {
  box-sizing: border-box;
}

@import "../../components/misc/config";

.billing_container {
  width: 100%;
  max-width: 1024px;
  margin: 1em auto;
  .billing_footer {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
  }
}

.billing_search_card {
  width: 90%;
  max-width: 500px;
  box-shadow: 0 0 20px #e1e1e1;
  margin: 6em auto;
  padding: 2em;

  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  input[type="text"] {
    display: block;
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #f1f1f1;

    &:focus {
      background-color: #f1f1f1;
    }
  }
}

.inline_search_box {
  width: 95%;
  max-width: 500px;
  margin: 2em auto;
  transition: all 400ms linear;
  position: relative;
  &.active {
    top: -50px;
  }
  .input_wrapper {
    width: 100%;
    box-shadow: 0 0 10px darken($light-color, 20);
    border: 2px solid darken($light-color, 10);
    height: 40px;
    background-color: $light-color;
    input[type="text"] {
      width: 90%;
      border: none;
      outline: none;
      padding-left: 20px;
      font-size: 8pt;
    }
    button {
      width: 10%;
      height: 100%;
      background-color: $primary-color;
      border: none;
      outline: none;
      transition: background-color 200ms linear;
      font-size: 10pt;
      &:hover {
        background-color: darken($primary-color, 20);
      }
      &.clear_input_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 10%;
        border: 0;
        background-color: $light-color;
        z-index: 100;
        &:hover {
          background-color: darken($light-color, 5);
          color: $danger-color;
          font-weight: 700;
        }
      }
    }
  }

  .searchbox_dropdown {
    background: $light-color;
    width: 100%;
    min-height: 30%;
    box-shadow: 0 0 10px darken($light-color, 10);
    padding: 1em;
    position: relative;
    top: 1px;
  }
}

.service_table {
  border: none;
  max-width: 900px;
  margin: 1em auto;
  thead {
    color: darken($light-color, 50);
  }
  tbody {
    tr {
      &:hover {
        background-color: darken($primary-color, 5);
      }
    }
    span{
        cursor: pointer;
    }
  }
  * {
    font-size: 11.5pt;
    th {
      font-weight: 600;
    }
  }

  .tag_data {
    &.tag_success {
      border-color: $primary-color;
    }
    text-align: center;
    span {
      border-radius: 5px;
      padding: 5px;
      border: 3px solid $primary-color;
    }
  }

  button {
    border: none;
    outline: none;
    font-size: 10pt;
    margin-top: 10px;
    i {
      font-size: 8pt;
    }
  }

  input,
  .button,
  select {
    padding: 10px;
    border: none;
    outline: none;
    border-color: darken($light-color, 10);
    background-color: darken($light-color, 10);
    margin-bottom: 3px;
    width: 100%;
    box-sizing: border-box;
    font-size: 8pt;
  }
}

.sort-buttons {
  a {
    font-size: 8pt;
  }
}

.invoice_container {
  width: 100%;
  max-width: 1000px;
  margin: 1em auto;
  background-color: $light-color;
  min-height: 90vh;
  box-shadow: 0 0 10px darken($light-color, 10);
  padding: 1em;
  font-size: 10.5pt;

  h4 {
    font-weight: 800;
    color: darken($light-color, 66);
  }
  * {
    font-size: 11pt;
  }
  td{
    .MUIDataTableBodyCell-root-71{
      // font-size: 8pt !important;
    }
  }
  > .row {
    padding: 1em;
    box-sizing: border-box;
    border-top: 2px solid darken($light-color, 10);
  }
  > .row:nth-of-type(2),
  > .row:nth-of-type(5) {
    // background-color: darken($light-color,30);
    p {
      line-height: 0.5;
    }
  }

  .invoice_header {
    padding: 2em;
    background-color: $primary-color;
    color: $light-color;
    font-weight: 700;
    p {
      line-height: 0.8;
    }

    div {
      padding-left: 3em;
    }
    img {
      width: 250px;
    }
    span {
      display: block;
    }
  }

  .invoice_footer {
    display: flex;
    justify-content: space-between;
    padding: 0 3em;
    padding-top: 3em;
  }
}

.scrollable_x {
  overflow-x: scroll;
}

.coe_billing_sidenav {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ul {
    list-style: none;
    li {
      padding: 15px;
      background-color: $light-color;
      font-size: 10pt;
      text-align: center;
      transition: all 400ms linear;
      font-weight: 400;
      &.active, &:hover {
        box-shadow: 0 0 10px darken($light-color, 10);
        color: $dark-color;
        font-weight: 600;
        background: $primary-color;
      }
    }
  }
}

.show {
  display: block;
}
.hide {
  display: none;
}
