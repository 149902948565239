
@import '../misc/config';
// $light-color: #fff;

.modal{
    position: fixed;
    // top: 4em;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(darken($light-color,20),.4);
    z-index: 1000;
    .modal_content{
        width: 90%;
        max-width: 500px;
        background-color: $light-color;
        // min-height: 100px;
        // max-height: 65vh;
        // overflow-y: scroll;
        // margin: 4em auto;
        // height: 0;
        box-shadow: 0 0 10px darken($light-color,40);
        &.fullscreen{
            max-width: none;
        }

        .modal_header{
            border-bottom: 2px solid darken($light-color, 10);
            background-color: $light-color;
            span{
                cursor: pointer;
                font-weight: 600;
                color: $danger-color;
            }
            &.danger{
                background-color: darken($danger-color, 5);
                color: $light-color;
                span{
                    color: $light-color;
                }
            }
            .title_container{
                display: flex;
                justify-content: space-between;
                padding: 1em;
                h4{
                    font-size: 14pt;
                    font-weight: 600;
                }
                a{
                    text-decoration: none;
                    font-weight: 600;
                    color: #df2222;
                }
            }
        }

        .modal_footer{
            border-top: 2px solid darken($light-color,10);
            padding: 1em;
            background-color: $light-color;
        }

        .modal_body{
            background: darken($light-color, 5);
            border: 2px solid $light-color;
            padding: 2em;
            // min-height: 200px;
            max-height: 70vh;
            overflow-y: scroll;
        }
    }
}

@media screen and (max-width:800px) {
    .modal{
        .modal_content{
            max-height: 65vh;
        }
    }
}