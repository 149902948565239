@import '../../misc/config';

.label{
    text-align: left;
    margin: 15px auto;
    font-size: 0.8em;
    display: block;
    color: lighten($black, 40);
    font-weight: 600;
}

@media (max-width: 768px) {
    .label{
        font-size: 0.9em;
    }
}