.select{
    display: inline-block;
    width: 100%;
    border: 1px solid #e9e9e9;
    padding: .5rem .3rem;
    font-size: 12pt;
    border-radius: 20px;
    cursor: pointer;
    option{
        font-size: 12pt;
    }
}