@import '../../components/misc/config';

.billing_details{
    table{
        td, th{
            font-size: 10pt;
        }
    }
    h6{
        font-size: 12pt;
        color: lighten($dark-color, 20);
        font-weight: 700;
    }
    h5{
        font-size: 14pt;
    }

    span, p{
        font-size: 10pt;
    }

    div{
        margin-bottom: 1em;
    }
    p{
        line-height: 1.4;
        // border: 1px solid red;
        margin-bottom: 0;
    }
    small{
        margin-left: 15pt;
    }

    .underlined{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: 140%;
            left: 0;
            background-color: $light-color;
        }
    }
}

.time_badge{
    color: darken($primary-color, 20);
    padding: 3px 8px;
    border-radius: 20px;
    background-color: lighten($primary-color, 40);
}
