@import "../../misc/config";

.input_wrapper {
  position: relative;
  // background-color: darken($white, 10);
  width: 100%;
  height: 40px;
  // max-width: 400px;
  // margin: 0 auto;
  border: 1px solid darken($white, 5);
  background-color: darken($white, 5);
  input {
    border: 0;
    outline-width: 0;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 15px;
    color: #555;
    line-height: 40px;
    font-size: 10pt;
    width: 100%;
  }
  .icon_wrapper {
    position: absolute;
    display: flex;
    padding: 0 0.3rem;
    height: 100%;
    width: 40px;
    right: 0;
    top: 0;
    background-color: darken($white, 5);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: background-color 300ms ease-in-out;
    &:hover {
      background-color: darken($white, 15);
    }
    i,
    svg {
      font-size: 12pt;
      color: lighten($black, 40);
      font-weight: 700;
    }
  }
}

.radio_wrapper {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  input {
    border: 0;
    outline-width: 0;
    background-color: #555;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 15px 0px;
  }
  span{
    color: #555;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 5px;
    font-size: 10pt;
  }
}
