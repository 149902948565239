@media screen and (max-width: 720px) {
    .desktopOnly{
        display: none;
    }
}

@media screen and (min-width: 720px) {
    .mobileOnly{
        display: none;
    }
}